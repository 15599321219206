var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    attrs: {
      "align": "stretch"
    }
  }, [_c('v-col', {
    staticClass: "d-flex justify-center align-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "text-center ",
    staticStyle: {
      "height": "100%",
      "overflow-y": "auto",
      "overflow-x": "hidden"
    },
    attrs: {
      "flat": "",
      "max-width": "600"
    }
  }, [_c('v-card-title', {
    staticClass: "justify-center"
  }, [_vm._v(_vm._s(_vm.$t("sign.DocumentTemplateDetailEdit.title")) + " ")]), _c('v-card-subtitle', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('sign.DocumentTemplateDetailEdit.subtitle'))
    }
  }), _c('v-card-text', [_c('v-img', {
    staticClass: "mt-8",
    attrs: {
      "align": "center",
      "justify": "center",
      "contain": "",
      "max-height": "250px",
      "src": require("@/assets/undraw_pending_approval_xuu9.svg")
    }
  })], 1), _vm.baseUrls && _vm.baseUrls.length > 1 ? _c('v-card-actions', [_c('v-select', {
    staticClass: "mb-n6",
    attrs: {
      "label": _vm.$t('sign.DocumentTemplateDetailEdit.selectUrl'),
      "items": _vm.baseUrls,
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.url,
      callback: function callback($$v) {
        _vm.url = $$v;
      },
      expression: "url"
    }
  })], 1) : _vm._e(), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "elevation": "0",
      "block": "",
      "color": "primary"
    },
    on: {
      "click": _vm.goToSendMail
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("sign.DocumentTemplateDetailEdit.sendMail")) + " ")])], 1), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "outlined": "",
      "block": "",
      "text": "",
      "color": "gray"
    },
    on: {
      "click": _vm.copyLinkToClipboard
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("sign.DocumentTemplateDetailEdit.copyLinkToClipboard")) + " ")])], 1), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "outlined": "",
      "block": "",
      "text": "",
      "color": "gray"
    },
    on: {
      "click": _vm.goToSignRequest
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("sign.DocumentTemplateDetailEdit.takeALooksie")) + " ")])], 1), _c('v-card-text', [_vm._v(" " + _vm._s(_vm.$t("sign.DocumentTemplateDetailEdit.text")) + " ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }